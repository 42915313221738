<template>
    <div>
        <h3 v-if="currentUser.role==='hr admin'">H R Admin Homepage</h3>
        <h3 v-if="currentUser.role==='catalogue admin'">Catalogue Admin Homepage</h3>
        <h3 v-if="currentUser.role==='purchase admin'">Purchase Admin Homepage</h3>
        <h3 v-if="currentUser.role==='warehouse admin'">Warehouse Admin Homepage</h3>
        <h3 v-if="currentUser.role==='finance admin'">Finance Admin Homepage</h3>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name     : 'dashboard',
    computed : {
        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped>

</style>
